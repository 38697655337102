import { Divider } from "@mui/material";
import Cookies from "js-cookie";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { useLogin } from "react-facebook";
import { useDispatch } from "react-redux";
import { setItem } from "../../helpers/data_management";
import { getLocaleCopies } from "../../helpers/locale_management";
import { sendTrackingEvent } from "../../helpers/tracking_management";
import useTriggerSnackbar from "../../hooks/useTriggerSnackbar";
import {
  getUserInfo,
  socialMediaLogin,
  login as userLogin,
} from "../../redux/actions/user_action";
import * as types from "../../redux/types/user_type";
import {
  BoxStyled,
  ContainerStyled,
  EmptyContainer,
  FacebookButton,
  FacebookIcon,
  GridStyled,
  LinkStyled,
  LoadingButtonStyled,
  LoadingIndicator,
  OuterBoxStyled,
  TextFieldStyled,
  TypographyStyled,
} from "./styles";

const Login = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [loggingIn, isLogginIn] = useState(false);
  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  let params;

  const { login, isLoading, error } = useLogin();

  const handleFacebookLogin = async () => {
    try {
      const response = await login({
        scope: "email",
      });

      params = {
        provider: "facebook",
        accessToken: response?.authResponse?.accessToken,
      };

      const registerRes = await dispatch(socialMediaLogin(params));

      if (registerRes.type === types.LOGIN_SUCCESS) {
        let userId = registerRes?.payload?.user?.id;

        triggerSnackbarFunc({
          message: copies.loginSuccess,
          severity: "success",
        });

        Cookies.set("isRegistered", userId ? true : false, {
          expires: 365,
        });

        sendTrackingEvent({
          event: "CE_login",
          method: "facebook",
        });

        router.push("/");
      }
    } catch (error) {
      // if register is not successsul
      triggerSnackbarFunc({
        message: copies.signupFail,
        severity: "error",
      });
    }
  };

  const handleSubmit = async (event) => {
    setEmailErr(false);
    setPasswordErr(false);
    isLogginIn(true);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    if (data.get("email") && data.get("password")) {
      setEmailErr(false);
      setPasswordErr(false);
      let params = {
        canCancel: true,
        email: data.get("email"),
        password: data.get("password"),
      };

      dispatch(userLogin(params)).then((response) => {
        let userId = response?.payload?.user?.id;
        if (response.type === types.LOGIN_SUCCESS) {
          isLogginIn(false);
          triggerSnackbarFunc({
            message: copies.loginSuccess,
            severity: "success",
          });
          setItem(types.CLEVERTAP_INIT, "true");
          Cookies.set("isRegistered", userId ? true : false, {
            expires: 365,
          });
          dispatch(getUserInfo());
          router.push("/");
        } else if (response.type === types.LOGIN_FAILED) {
          isLogginIn(false);
          triggerSnackbarFunc({
            message: response?.errMsg ?? copies.errorLogin,
            severity: "error",
          });
        }
      });
    } else if (!data.get("email")) {
      isLogginIn(false);
      setEmailErr(true);
    } else if (!data.get("password")) {
      isLogginIn(false);
      setPasswordErr(true);
    }
  };

  const redirect = () => {
    router.replace("/signup");
  };

  const copies = getLocaleCopies();

  return (
    <ContainerStyled component="main" maxWidth="xs">
      <OuterBoxStyled>
        <EmptyContainer
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <TypographyStyled component="h1" variant="h5">
            {copies.login}
          </TypographyStyled>
          <EmptyContainer container width="50%" justifyContent="flex-end">
            <LinkStyled variant="body2" onClick={redirect}>
              {copies.dontHaveAcc}
            </LinkStyled>
          </EmptyContainer>
        </EmptyContainer>
        <BoxStyled
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextFieldStyled
            margin="normal"
            required
            fullWidth
            id="email"
            label={copies.email}
            name="email"
            autoComplete="email"
            autoFocus
            error={emailErr}
            helperText={emailErr ? copies.emailCantBlank : ""}
          />
          <TextFieldStyled
            margin="normal"
            required
            fullWidth
            name="password"
            label={copies.password}
            type="password"
            id="password"
            autoComplete="current-password"
            error={passwordErr}
            helperText={passwordErr ? copies.passwordCantBlank : ""}
          />
          <GridStyled item xs container justifyContent="flex-end">
            <LinkStyled href="/forgot-password" variant="body2">
              {copies.forgotPassword}
            </LinkStyled>
          </GridStyled>
          <LoadingButtonStyled
            id="log-masuk"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loggingIn}
            loadingIndicator={<LoadingIndicator size={20} />}
          >
            {copies.login}
          </LoadingButtonStyled>

          <Divider sx={{ mb: 2 }} />

          <FacebookButton
            fullWidth
            onClick={handleFacebookLogin}
            sx={{ mt: 0, mb: 2 }}
            disabled={isLoading}
            loadingIndicator={<LoadingIndicator size={20} />}
          >
            <EmptyContainer
              gap="15px"
              container
              height="auto"
              justifyContent="center"
            >
              <FacebookIcon />
              Continue with Facebook
            </EmptyContainer>
          </FacebookButton>
        </BoxStyled>
      </OuterBoxStyled>
    </ContainerStyled>
  );
};

export default Login;
