import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { styled as styling } from "@mui/material/styles";
import { Container } from "@mui/system";
import Color from "../../assets/colors";

export const ContainerStyled = styling(Container)({
  //
  height: "90vh",
  display: "flex",
  justifyContent: "center",
});
export const OuterBoxStyled = styling(Box)({
  marginTop: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
});

export const EmptyContainer = styling(Grid)({});

export const AvatarStyled = styling(Avatar)({
  //
});

export const LockIconStyled = styling(LockOutlinedIcon)({
  //
});
export const TypographyStyled = styling(Typography)({
  //
});

export const BoxStyled = styling(Box)({
  //
});

export const TextFieldStyled = styling(TextField)({
  //
});

export const GridStyled = styling(Grid)({
  //
});

export const LinkStyled = styling(Link)({
  //
});

export const LoadingButtonStyled = styling(LoadingButton)({
  //
});

export const LoadingIndicator = styling(CircularProgress)({
  color: Color.white,
});

export const FacebookButton = styling(LoadingButton)({
  border: "1px solid #d9d9d9",
  color: "black",
  textTransform: "none",
  borderRadius: "6px",
});

export const FacebookIcon = styling(FacebookRoundedIcon)({
  color: "#4267B2",
});
